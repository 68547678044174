import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Latest NFTs",
      paragraph:
        "Below are my three latest minted pieces. Click on them to go to the auction page.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require("../../assets/pieces/cena.png")}
                      alt="Video"
                      width={300}
                      height={200}
                    />
                  </a>
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require("../../assets/pieces/fumar.jpg")}
                      alt="Video"
                      width={300}
                      height={200}
                    />
                  </a>
                </div>
              </div>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <a href="https://google.com" target="_blank" rel="noopener noreferrer">
                    <Image
                      src={require("../../assets/pieces/notas.png")}
                      alt="Video"
                      width={300}
                      height={200}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
