/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import { Link } from 'react-router-dom';

class Secondary extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    const genericSection01Header = {
      title: 'Do you already own a piece of mine?'
    } 
    
    const genericSection02Header = {
      title: 'Follow my art'
    } 


    return (
      <React.Fragment>
        <FeaturesSplit invertMobile imageFill />

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0 center-content">How to interact with them</h2>

            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>

            
          </div>
        </GenericSection>

        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection01Header} className="center-content" />
            <div className="center-content">
            <p>If you are already an owner of my work, then there is an exclusive collector section with benefits and perks waiting for you!</p>
             <Link to="/collectors/" className="button button-primary button-wide-mobile button-lg">
              Go to collector section
             </Link>
            </div>
          </div >
        </GenericSection>         
        
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader data={genericSection02Header} className="center-content" />
            <div className="center-content">
             <a href="https://superrare.com/fakito" target="_blank" className="button button-primary button-wide-mobile button-lg" style={{"margin-right": "20px"}}>
              SuperRare
             </a>
             <a href="https://opensea.io/fakito" target="_blank" className="button button-primary button-wide-mobile button-lg">
              Opensea
             </a>
            </div>
          </div >
        </GenericSection>         

      </React.Fragment>
    );
  }
}


export default Secondary;