/* eslint-disable react/jsx-no-target-blank */
import React from "react";
// import section header
import SectionHeader from "../components/sections/partials/SectionHeader";
// import sections
import HeroSplit from "../components/sections/HeroSplit";
import GenericSection from "../components/sections/GenericSection";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";
// import some required elements
import Image from "../components/elements/Image";
import Modal from "../components/elements/Modal";

class Home extends React.Component {
  state = {
    videoModalActive: false,
  };
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  };

  render() {
    const genericSection01Header = {
      title: "Latest Work",
      paragraph:
        "These are my three latest pieces. Click on them to go to the post.",
    };
    const genericSection02Header = {
      title: "Latest NFTs",
      paragraph:
        "Below are my three latest minted pieces. Click on them to go to the auction page.",
    };

    return (
      <React.Fragment>
        <HeroSplit className="illustration-section-01" />
        <GenericSection topDivider className="center-content">
          <SectionHeader
            data={genericSection01Header}
            className="reveal-from-bottom"
          />
          <div
            className="reveal-from-bottom"
            style={{
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-evenly",
            }}
          >
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/inevitable.png")}
                width={200}
                height={400}
              />
            </a>
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/cena.png")}
                width={200}
                height={400}
              />
            </a>
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/notas.png")}
                width={200}
                height={400}
              />
            </a>
          </div>
          <GenericSection
            topDivider
            className="center-content"
          ></GenericSection>
          <SectionHeader
            data={genericSection02Header}
            className="reveal-from-bottom"
          />
          <div
            className="reveal-from-bottom"
            style={{
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-evenly",
            }}
          >
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/fumar.jpg")}
                width={200}
                height={400}
              />
            </a>
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/cena.png")}
                width={200}
                height={400}
              />
            </a>
            <a href="https://www.superrare.com/fakito" target="_blank">
              <Image
                src={require("./../assets/pieces/notas.png")}
                width={200}
                height={400}
              />
            </a>
          </div>
          <Modal
            id="video-modal"
            show={this.state.videoModalActive}
            handleClose={this.closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          />
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Home;
