/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import FeaturesSplit from '../components/sections/FeaturesSplit';
import GenericSection from '../components/sections/GenericSection';
import Team from '../components/sections/Team';
import { Link } from 'react-router-dom';

class Portfolio extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {  

    const genericSection01Header = {
      title: 'Do you already own a piece of mine?'
    } 
    
    const genericSection02Header = {
      title: 'Follow my art'
    } 


    return (
      <React.Fragment>
            <Team/>
      </React.Fragment>
    );
  }
}


export default Portfolio;